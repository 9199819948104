import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { FormikHelpers } from 'formik';
import isNumber from 'lodash/isNumber';
import { Addr } from '@neo1/core/utils/addressValidation';
import { CompanyActivationData } from '@neo1/client/lib/entities/persistedUserData/types';
import { activateCompany as sendRequest } from 'modules/Authentification/redux/thunks';
import { CompanyNumberType } from '@neo1/client/lib/entities/company/constants';
import { saveCompanyPartialData } from 'modules/App/redux/persisted/thunks';
import { notifyError } from 'modules/App/redux/notifications/toaster/thunks';
import {
  Event as GtmEvent,
  sendEvent as sendGtmEvent,
} from 'contexts/instrumentation/gtm';
import MultiStepForm, { Step } from 'components/composed/MultiStepModalForm';
import useAddressValidation from 'components/elements/Google/useAddressValidation';
import usePersistedUserData from 'hooks/usePersistedUserData';
import { Events, useAmplitude } from 'contexts/instrumentation';
import { selectActingCompany } from '../redux/selectors';
import messages from './messages';
import {
  AboutView,
  FinishView,
  GetStartedView,
  OwnersView,
  Neo1FeaturesView,
} from './steps';
import {
  CompanyActivationStep,
  useValidateStep,
  isEditingOwner,
} from './utils';
import Address from './steps/Address';
import Owners from './steps/Owners';
import AddressForm from './steps/Address/Form';
import styles from './ActivateCompany.module.css';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
}

export default function ActivateCompany({
  onClose: onCloseProp,
  isOpen,
}: Props) {
  const intl = useIntl();

  const company = useSelector(selectActingCompany);

  const {
    data: { companyActivation },
  } = usePersistedUserData();

  const getValues = () => ({
    id: company ? company.id : null,
    hasOwners: false,
    registrationNumber: null,
    registrationNumberType:
      company.countryCode === 'US'
        ? CompanyNumberType.Duns
        : CompanyNumberType.RegistrationNumber,
    numberOfEmployee: 0,
    yearOfCreation: '',
    companyKind: null,
    tradeName: '',
    name: company ? company.name : '',
    dismissedTips: [],
    industry: null,
    vatNumber: null,
    address: '',
    zipCode: '',
    city: '',
    state: null,
    owners: [],
    currentStep: 0,
    editedOwnerIndex: null,
    contactEmail:
      companyActivation?.contactEmail || company?.contactEmail || '',
    phoneNumber: companyActivation?.phoneNumber || company?.phoneNumber || '',
    country: company.countryCode,
    ...companyActivation,
  });

  const {
    id,
    city,
    name,
    numberOfEmployee,
    editedOwnerIndex,
    owners,
    industry,
    dismissedTips,
    zipCode,
    address,
    address2,
    companyKind,
    yearOfCreation,
    hasOwners,
    registrationNumber,
    registrationNumberType,
    tradeName,
    vatNumber,
    state,
    currentStep,
    country,
    expenseFeatureUsage,
    travelFeatureUsage,
    purchasingFeatureUsage,
    phoneNumber,
    contactEmail,
  } = getValues();

  const addressInitialValues = useMemo(
    () => ({
      zipCode,
      address,
      address2,
      city,
      state,
      country,
      phoneNumber,
      contactEmail,
    }),
    [
      zipCode,
      address,
      address2,
      city,
      state,
      country,
      phoneNumber,
      contactEmail,
    ],
  );

  const aboutInitialValues = useMemo(
    () => ({
      yearOfCreation,
      registrationNumber,
      registrationNumberType,
      tradeName,
      vatNumber,
      companyKind,
      numberOfEmployee,
      name,
      industry,
      country,
    }),
    [
      yearOfCreation,
      registrationNumber,
      registrationNumberType,
      tradeName,
      vatNumber,
      companyKind,
      numberOfEmployee,
      name,
      industry,
      country,
    ],
  );

  const valuesToSubmit = useMemo(
    () => ({
      ...aboutInitialValues,
      ...addressInitialValues,
      owners,
      id,
      dismissedTips,
      hasOwners,
      editedOwnerIndex,
    }),
    [
      aboutInitialValues,
      addressInitialValues,
      dismissedTips,
      editedOwnerIndex,
      hasOwners,
      id,
      owners,
    ],
  );

  const dispatch = useDispatch();

  const [formCurrentStep, setformCurrentStep] = useState(currentStep);

  const {
    validateAboutStep,
    validateAddressStep,
    validateOwnersStep,
    validateFeatureUsage,
  } = useValidateStep();

  const { logEvent } = useAmplitude();

  const getOnSubmitEvent = (values: CompanyActivationData): string => {
    switch (formCurrentStep) {
      case CompanyActivationStep.About:
        return Events.SubmitAbout;
      case CompanyActivationStep.Address:
        return Events.SubmitAddress;
      case CompanyActivationStep.Owners:
        if (isEditingOwner(values)) {
          return `save_owner${values.editedOwnerIndex + 1}`;
        }
        return Events.SubmitOwners;
      case CompanyActivationStep.Neo1Features:
        return Events.SubmitSummary;
      case CompanyActivationStep.Finish:
        return Events.CloseFinish;
      default:
        return null;
    }
  };

  const getOnCloseEvent = (values: CompanyActivationData) => {
    switch (formCurrentStep) {
      case CompanyActivationStep.GetStarted:
        return Events.CloseGetStarted;
      case CompanyActivationStep.About:
        return Events.CloseAbout;
      case CompanyActivationStep.Address:
        return Events.CloseAddress;
      case CompanyActivationStep.Owners:
        if (isEditingOwner(values)) {
          return Events.CloseOwnersEditing;
        }
        return Events.CloseOwners;
      case CompanyActivationStep.Neo1Features:
        return Events.CloseSummary;
      case CompanyActivationStep.Finish:
        return Events.CloseFinish;
      default:
        return null;
    }
  };

  const getGoBackEvent = useCallback(() => {
    switch (formCurrentStep) {
      case CompanyActivationStep.About:
        return Events.BackBtnAbout;
      case CompanyActivationStep.Address:
        return Events.BackBtnAddress;
      case CompanyActivationStep.Owners:
        return Events.BackBtnOwners;
      case CompanyActivationStep.Neo1Features:
        return Events.BackBtnSummary;
      default:
        return null;
    }
  }, [formCurrentStep]);

  const onClose = (values: CompanyActivationData) => {
    onCloseProp();
    logEvent({ event: getOnCloseEvent(values) });
  };

  const renderSideContent = (
    step: keyof typeof messages.side,
    title: string,
    desc: string,
    infos?: string[],
  ) => {
    const messageObj = messages.side[step];
    if (!messageObj) return null;
    return (
      <div className={styles.sideContent}>
        <h3 className={styles.subTitle}>
          {intl.formatMessage(messageObj[title])}
        </h3>
        <div className={styles.desc}>
          {intl.formatMessage(messageObj[desc])}
        </div>
        {infos &&
          infos.map((info) => (
            <div key={info} className={styles.desc}>
              {intl.formatMessage(messageObj[info])}
            </div>
          ))}
      </div>
    );
  };

  // submitFunctions
  const saveCompanyData = useCallback(
    async (newValues: CompanyActivationData) => {
      await dispatch(saveCompanyPartialData(newValues));
    },
    [dispatch],
  );
  const onSave = useCallback(
    async (values: CompanyActivationData) => {
      await saveCompanyData({ ...values, currentStep: formCurrentStep + 1 });
    },
    [saveCompanyData, formCurrentStep],
  );

  const onSubmitLog = useCallback(
    async (values: CompanyActivationData) => {
      logEvent({
        event: getOnSubmitEvent({ ...values }),
      });
      await onSave(values);
    },
    [onSave, logEvent, getOnSubmitEvent],
  );

  // back

  const onBack = useCallback(
    (values: CompanyActivationData) => {
      logEvent({ event: getGoBackEvent() });
      setformCurrentStep(Math.max(formCurrentStep - 1, 0));

      dispatch(
        saveCompanyPartialData({
          ...valuesToSubmit,
          ...values,
          currentStep: Math.max(formCurrentStep - 1, 0),
        }),
      );
    },
    [dispatch, formCurrentStep, getGoBackEvent, logEvent, valuesToSubmit],
  );

  const handleChangeStep = (key: CompanyActivationStep) => {
    setformCurrentStep(key);
  };
  const nextStepFromAddressStep = useCallback(() => {
    setformCurrentStep(CompanyActivationStep.Owners);
  }, [setformCurrentStep]);

  // Contents

  const onSubmitCompanyAddress = useCallback(
    (values: CompanyActivationData) =>
      onSubmitLog({ ...valuesToSubmit, ...values }),
    [valuesToSubmit, onSubmitLog],
  );
  const {
    isSubmitDisabled,
    isShowingValidationResult,
    onSubmitFromAddressComponent: onSubmitAddress,
    addressView,
    onBack: onBackAddressValidation,
  } = useAddressValidation({
    onSubmit: onSubmitCompanyAddress,
    form: <AddressForm />,
    goToNextStep: nextStepFromAddressStep,
    getAddrValues: (values: CompanyActivationData) => ({
      streetLine1: values.address,
      streetLine2: values.address2,
      zipCode: values.zipCode,
      city: values.city,
      state: values.state,
      countryCode: values.country,
    }),
    getFormValues: (values: CompanyActivationData, addr: Addr) => ({
      ...values,
      address: addr.streetLine1,
      address2: addr.streetLine2,
      zipCode: addr.zipCode,
      city: addr.city,
      state: addr.state,
      country: addr.countryCode,
    }),
  });
  const addressContent = (
    <Address
      isSubmitDisabled={isSubmitDisabled}
      isShowingValidationResult={isShowingValidationResult}
      onBackAddressValidation={onBackAddressValidation}
      onBack={onBack}
    >
      {addressView}
    </Address>
  );

  const contentGetStarted = <GetStartedView className={styles.form} />;

  const aboutContent = <AboutView className={styles.form} onBack={onBack} />;

  const onOwnerSubmit = async (
    values: CompanyActivationData,
    { setValues }: FormikHelpers<CompanyActivationData>,
  ) => {
    if (isEditingOwner(values)) {
      setValues({ ...values, editedOwnerIndex: null });
    } else {
      await onSubmitLog({
        ...valuesToSubmit,
        ...values,
      });
    }
  };
  const {
    isSubmitDisabled: isOwnerSubmitDisabled,
    isShowingValidationResult: isShowingOwnerValidationResult,
    onSubmitFromAddressComponent: onSubmitOwner,
    addressView: ownersAddressView,
    onBack: onBackOwnerAddressValidation,
  } = useAddressValidation({
    onSubmit: onOwnerSubmit,
    form: <Owners />,
    getAddrValues: (values: CompanyActivationData) => {
      if (values.hasOwners && isNumber(values.editedOwnerIndex)) {
        const {
          street,
          city: ownerCity,
          state: ownerState,
          country: ownerCountry,
          zipCode: ownerZipCode,
        } = values.owners[values.editedOwnerIndex];
        return {
          streetLine1: street,
          streetLine2: '',
          zipCode: ownerZipCode,
          city: ownerCity,
          state: ownerState,
          countryCode: ownerCountry,
        };
      }
      return null;
    },
    getFormValues: (values: CompanyActivationData, addr: Addr) => {
      let newValues = values;
      if (values.hasOwners && isNumber(values.editedOwnerIndex)) {
        const {
          streetLine1,
          countryCode,
          city: ownerCity,
          state: ownerState,
          zipCode: ownerZipCode,
        } = addr;
        const newOwners = [...values.owners];
        newOwners[values.editedOwnerIndex] = {
          ...values.owners[values.editedOwnerIndex],
          street: streetLine1,
          city: ownerCity,
          state: ownerState,
          country: countryCode,
          zipCode: ownerZipCode,
        };
        newValues = {
          ...values,
          owners: [...newOwners],
        };
      }
      return newValues;
    },
  });

  const ownersContent = (
    <OwnersView
      isSubmitDisabled={isOwnerSubmitDisabled}
      isShowingOwnerValidationResult={isShowingOwnerValidationResult}
      onBackOwnerAddressValidation={onBackOwnerAddressValidation}
      className={styles.form}
      onBack={onBack}
    >
      {ownersAddressView}
    </OwnersView>
  );

  const summaryContent = (
    <Neo1FeaturesView className={styles.form} onBack={onBack} />
  );

  const finishContent = <FinishView className={styles.form} />;

  // Steps
  const steps: Step[] = [
    {
      key: CompanyActivationStep.GetStarted,
      label: 'Business registration',
      content: contentGetStarted,
      sideContent: renderSideContent('getStarted', 'title', 'desc', [
        'info',
        'secondInfo',
        'thirdInfo',
      ]),
      onSubmit: (values: CompanyActivationData) =>
        onSave({ ...valuesToSubmit, ...values }),
    },
    {
      key: CompanyActivationStep.About,
      label: intl.formatMessage(messages.about),
      futureTrack: true,
      content: aboutContent,
      sideContent: renderSideContent('about', 'title', 'desc'),
      onSubmit: (values: CompanyActivationData) =>
        onSubmitLog({
          ...valuesToSubmit,
          ...values,
        }),
      validate: validateAboutStep,
      initialValues: aboutInitialValues,
    },
    {
      key: CompanyActivationStep.Address,
      label: intl.formatMessage(messages.address),
      content: addressContent,
      sideContent: renderSideContent('address', 'title', 'desc'),
      onSubmit: onSubmitAddress,
      validate: validateAddressStep,
      // We use `isPartialSubmit` to prevent going to the next step on MultiStepForm,
      // the change step is handled on useAddressValidation.
      isPartialSubmit: () => true,
      initialValues: addressInitialValues,
    },
    {
      key: CompanyActivationStep.Owners,
      label: intl.formatMessage(messages.owners),
      content: ownersContent,
      sideContent: renderSideContent('owners', 'title', 'desc', [
        'info',
        'secondInfo',
      ]),
      onSubmit: onSubmitOwner,
      validate: validateOwnersStep,
      initialValues: { owners, hasOwners },
      isPartialSubmit: (values: CompanyActivationData) =>
        isEditingOwner(values),
    },
    {
      key: CompanyActivationStep.Neo1Features,
      label: intl.formatMessage(messages.neo1Features),
      content: summaryContent,
      sideContent: renderSideContent('neo1Features', 'title', 'desc', [
        'secondInfo',
        'thirdInfo',
        'fourthInfo',
      ]),
      onSubmit: async (values: CompanyActivationData) => {
        await dispatch(
          sendRequest({
            ...valuesToSubmit,
            ...values,
          }),
        );
        await onSubmitLog({
          ...valuesToSubmit,
          ...values,
        });
        sendGtmEvent(GtmEvent.Stage2Finished);
      },
      validate: validateFeatureUsage,
      initialValues: {
        expenseFeatureUsage,
        travelFeatureUsage,
        purchasingFeatureUsage,
      },
    },
    {
      key: CompanyActivationStep.Finish,
      hideHeader: true,
      content: finishContent,
      sideContent: renderSideContent('finish', 'title', 'desc'),
      initialValues: {},
      onSubmit: (values: CompanyActivationData) => {
        logEvent({
          event: getOnSubmitEvent({
            ...valuesToSubmit,
            ...values,
          }),
        });
        onClose({ ...valuesToSubmit, ...values });
      },
    },
  ];
  return (
    <MultiStepForm
      isOpen={isOpen}
      title={intl.formatMessage(messages.modalTitle)}
      onClose={onClose}
      currentStep={formCurrentStep}
      onChangeStep={handleChangeStep}
      steps={steps}
      onSubmitError={(e) => {
        dispatch(notifyError(e));
      }}
    />
  );
}

ActivateCompany.defaultProps = {
  isOpen: false,
};
