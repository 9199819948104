export type UUID = string;

export type Email = string;

export type HexaColor = string;

export type DataUrl = string;

export enum RpcStringLengths {
  standardName = 30,
  id = 100,
  longName = 150,
  description = 255,
  loyaltyCardNumber = 20,
  travelDocDisplayName = 50,
  passportNumber = 30,
  password = 100,
  KnownTravelerNumber = 25,
  RedressControlNumber = 13,
  DriverLicense = 256,
  UKCompanyNumber = 8,
  CardEmbossedName = 50,
  CardDisplayName = 50,
  // DEV-4329: As we don't validate US company/business numbers, we don't restrict the field value
  // At this time the backend will accept a string of any length
  USCompanyNumber = undefined,
  passportIssuingAuthority = 30,
  extraLargeName = 80,
}

/*
 * [DEV-3659] Hotfix
 * This is the exact same regex used in the backend
 * implemented in class uk.gov.hmrc.emailaddress.EmailAddress
 * /!\ Change this with care
 */
export const EMAIL_REGEX =
  /^((?!\.)(?!.*?\.\.)[a-zA-Z0-9.!#$%&’'"*+\/=?^_`{|}~-]*[a-zA-Z0-9!#$%&’'"*+\/=?^_`{|}~-]+)@((?!-)[a-zA-Z0-9-.\[]+\.[a-zA-Z0-9-.\]]+)$/;

const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const UUID_REGEX_AS_STRING = UUID_REGEX.toString().slice(2, -3);

const DATA_URL_REGEX = /^data:([\w+-]+\/[\w+.-]+)?[,;]/;

const COLOR_HEXA_REGEX = /^#?(?:[0-9a-f]{3}|[0-9a-f]{6})$/i;

const TRADE_NAME_REGEX =
  /^(?!.*\/\/)[a-zA-Z\d @#$%&,.:;?!'"()[\]{}+\-~_^`|\\/]{2,100}$/;

const COMPANY_NAME_REGEX = /^(?!.*\/\/)[a-zA-Z\d \-.,'&()/]{2,100}$/;

const FIRST_NAME_REGEX =
  /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F \-.,'()]{1,50}$/;

const LAST_NAME_REGEX =
  /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F \-.,'()]{1,50}$/;

const FULL_NAME_REGEX =
  /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F \-.,'()]{1,50} [a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F \-.,'()]{1,50}$/;

const ADDRESS_NAME_REGEX =
  /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F\d \-.,'&()]{1,50}$/;

const COUNTRY_STATE_REGEX =
  /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F\d \-.&']{1,50}$/;

const CITY_REGEX =
  /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F\d \-.,']{1,100}$/;

const ZIP_CODE_REGEX = /^[a-zA-Z\d \-]{4,15}$/;

const STREET_REGEX =
  /^(?!.*\/\/)[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F\d \-.,'()&#/]{2,60}$/;

const EMPLOYEE_ID_REGEX = /^[a-zA-Z\d\-_. #]{1,50}$/;

const BANKING_ID_REGEX = /^\d{6}$/;

export const ROOT_UUID = '00000000-0000-0000-0000-000000000000';

export const isUUID = (uuid: string) =>
  UUID_REGEX.test(uuid) || ROOT_UUID === uuid;

export const isEmail = (email: string): email is Email =>
  EMAIL_REGEX.test(email);

export const isDataUrl = (url: string): url is DataUrl =>
  DATA_URL_REGEX.test(url);

export const isHexaColor = (color: string): color is HexaColor =>
  COLOR_HEXA_REGEX.test(color);

export const isUrl = (url: string) => {
  try {
    new URL(url);
  } catch (err) {
    return false;
  }
  return true;
};

export const isTradeName = (tradeName: string) =>
  TRADE_NAME_REGEX.test(tradeName);

export const isCompanyName = (companyName: string) =>
  COMPANY_NAME_REGEX.test(companyName);

export const isRecaptcha = (recaptcha: string) => recaptcha.length >= 1;

export const isFirstName = (firstName: string) =>
  FIRST_NAME_REGEX.test(firstName);

export const isLastName = (lastName: string) => LAST_NAME_REGEX.test(lastName);

export const isFullName = (fullName: string) => FULL_NAME_REGEX.test(fullName);

export const isAddressName = (addressName: string) =>
  ADDRESS_NAME_REGEX.test(addressName);

export const isCountryState = (countryState: string) =>
  COUNTRY_STATE_REGEX.test(countryState);

export const isCity = (city: string) => CITY_REGEX.test(city);

export const isZipCode = (zipCode: string) => ZIP_CODE_REGEX.test(zipCode);

export const isStreet = (street: string) => STREET_REGEX.test(street);

export const isEmployeeId = (employeeId: string) =>
  EMPLOYEE_ID_REGEX.test(employeeId);

export const isOpaqueString = (str: string) =>
  str.length >= 1 && str.length <= 1024;

export const isBankingId = (bankingId: string) =>
  BANKING_ID_REGEX.test(bankingId);

export const TRADE_NAME_RULES =
  'Content should be between: 2 and 100 characters, only the following characters allowed: letter, number, space and @ # $ % & , . : ; ? ! \' " ( ) [ ] { } + - ~ _ ^ ` | \\ /, but consecutive / are not allowed (E.g. // or ///)';

export const COMPANY_NAME_RULES =
  "Content should be between: 2 and 100 characters, only the following characters allowed: letter, number, space and - . , ' & ( ) /, but consecutive / are not allowed (E.g. // or ///)";

export const FIRST_NAME_RULES =
  "Content should be between: 1 and 50 characters, only the following characters allowed: letter, accented character, space and - . , ' ( )";

export const LAST_NAME_RULES =
  "Content should be between: 1 and 50 characters, only the following characters allowed: letter, accented character, space and - . , ' ( )";

export const FULL_NAME_RULES =
  "Please separate your first and last name by space, each one should be between: 1 and 50 characters, only the following characters allowed: letter, accented character, space and - . , ' ( )";

export const ADDRESS_NAME_RULES =
  "Content should be between: 1 and 50 characters, only the following characters allowed: letter, accented character, number, space and - . , ' & ( )";

export const COUNTRY_STATE_RULES =
  "Content should be between: 1 and 50 characters, only the following characters allowed: letter, accented character, number, space and - . & '";

export const CITY_RULES =
  "Content should be between: 1 and 100 characters, only the following characters allowed: letter, accented character, number, space and - . , '";

export const ZIP_CODE_RULES =
  'Content should be between: 4 and 15 characters, only the following characters allowed: letter, number, space and -';

export const STREET_RULES =
  "Content should be between: 2 and 60 characters, only the following characters allowed: letter, accented character, number, space and - . , ' ( ) & # /, but consecutive / are not allowed (E.g. // or ///)";

export const EMPLOYEE_ID_RULES =
  'Content should be between: 1 and 50 characters, only the following characters allowed: letter, number, space and - . _ #';

export const BANKING_ID_RULES = 'Banking id should be a 6 digits number';
